@import '../../assets/fonts/fonts.scss';

.primaryClass {
    text-transform: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
}

.disabledClass {
    text-transform: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #878787;
}
  