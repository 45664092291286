@import '../../../assets/fonts/fonts.scss';
@import '../../../assets/colors/colors.scss';

.supportFormParent,
.darkModeSupportFormParent {
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    align-content: center;
    justify-content: center;
}

.supportForm {
    padding-top: 20px;
    margin-top: 20px;
    margin-bottom: 90px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $greyColor;
    border-radius: 25px;
}

.darkModeSupportForm {
    padding-top: 20px;
    margin-top: 20px;
    margin-bottom: 90px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $greyColor;
    border-radius: 25px;
}

.darkModeEntireVisualFileEditorSection .darkModeSupportForm {
    padding-top: 20px;
    margin-top: 20px;
    margin-bottom: 90px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $darkModeBackgroundColor;
    border-radius: 25px;
}

.formTitle {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    text-align: center;
}

.darkModeFormTitle {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    text-align: center;
    color: white;
}

.formDescription {
    font-family: SanFranciscoTextRegular;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    text-align: center;
}

.darkModeFormDescription {
    font-family: SanFranciscoTextRegular;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    text-align: center;
    color: white;
}

.formFields {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 12px;
}

.darkModeEntireVisualFileEditorSection .darkModeSupportForm #appVersionLabel,
#platformLabel {
    color: grey;
    margin-top: 10px;
}

@media screen and (min-width: 700px) {

    .supportForm,
    .darkModeEntireVisualFileEditorSection .darkModeSupportForm {
        width: 700px;
    }
}