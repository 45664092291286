@import '../../../assets/colors/colors.scss';
@import '../../../assets/fonts/fonts.scss';

.imagePreviews {
    background-color: $greyColor;
    margin-bottom: 20px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    border-radius: 25px;
}

.darkModeImagePreviews {
    background-color: $blackColor;
    margin-bottom: 20px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    border-radius: 25px;
}

.imagePreviewTitle {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    margin-top: 10px;
}

.darkModeImagePreviewTitle {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    margin-top: 10px;
    color: white;
}

.imagePreviewDescription {
    font-family: SanFranciscoTextRegular;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: -30px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: center;
}

.darkModeImagePreviewDescription {
    font-family: SanFranciscoTextRegular;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: -30px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: center;
    color: white;
}

.entireVisualFileEditorSection .visualFileEditorImages {
    padding-bottom: 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.darkModeEntireVisualFileEditorSection .darkModeVisualFileEditorImages {
    padding-bottom: 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.entireVisualFileEditorSection .visualFileEditorImages .visualFileEditorActualImage {
    background-color: $greyColor;
    padding: 1px;
    border-radius: 25px;
    margin-left: unset;
    margin-right: unset;
    // margin-left: 2%;
}

.darkModeEntireVisualFileEditorSection .darkModeVisualFileEditorImages .darkModeVisualFileEditorActualImage {
    background-color: $darkModeBackgroundColor;
    padding: 1px;
    border-radius: 25px;
    margin-left: unset;
    margin-right: unset;
    // margin-left: 2%;
}

.iOSPreviews,
.iPadOSPreviews {
    margin-left: 1.5%;
}

.visualFileEditorImageTitle {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 15px;
    padding-bottom: 10px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.darkModeVisualFileEditorImageTitle {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 15px;
    padding-bottom: 10px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

@media screen and (min-width: 400px) {

    .imagePreviewTitle,
    .darkModeImagePreviewTitle {
        font-size: 25px;
    }

    .imagePreviewDescription,
    .darkModeImagePreviewDescription {
        font-size: 20px;
    }

    .visualFileEditorImageTitle,
    .darkModeVisualFileEditorImageTitle {
        font-size: 20px;
    }
}

@media screen and (min-width: 600px) {

    .visualFileEditorImageTitle,
    .darkModeVisualFileEditorImageTitle {
        font-size: 12px;
        margin-left: -5%;
    }

    .iOSPreviews,
    .iPadOSPreviews {
        margin-left: 2%;
    }
}

@media screen and (min-width: 750px) {

    .visualFileEditorImageTitle,
    .darkModeVisualFileEditorImageTitle {
        font-size: 15px;
        margin-left: 0%;
    }
}

@media screen and (min-width: 900px) {

    .visualFileEditorImageTitle,
    .darkModeVisualFileEditorImageTitle {
        font-size: 18px;
        margin-left: -2%;
    }
}

@media screen and (min-width: 1250px) {

    .visualFileEditorImageTitle,
    .darkModeVisualFileEditorImageTitle {
        margin-left: -3%;
    }
}

@media screen and (min-width: 1400px) {

    .visualFileEditorImageTitle,
    .darkModeVisualFileEditorImageTitle {
        margin-left: -4%;
    }
}