@import '../../assets/fonts/fonts.scss';
@import '../../assets/colors/colors.scss';

.contactFormParent,
.darkModeContactFormParent {
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
}

.nameAndDescription .description {
    font-family: SanFranciscoTextRegular;
    font-size: 18.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-left: 5%;
    padding-right: 5%;
}

.darkModeNameAndDescription .darkModeDescription {
    font-family: SanFranciscoTextRegular;
    font-size: 18.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    padding-left: 5%;
    padding-right: 5%;
}

.contactsBackground .optionBackground {
    background-color: $greyColor;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 25px;
}

.darkModeContactsBackground .darkModeOptionBackground {
    background-color: $darkModeBackgroundColor;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 25px;
}

.contactsBackground .optionBackground .largeTitleBackground {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.darkModeContactsBackground .darkModeOptionBackground .darkModeLargeTitleBackground {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
}

.contactsBackground .largeTitle {
    font-family: SanFranciscoDisplaySemibold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    margin-bottom: 5px;
}

.darkModeContactsBackground .darkModeLargeTitle {
    font-family: SanFranciscoDisplaySemibold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    margin-bottom: 5px;
    color: white;
}

.contactsBackground .description {
    font-family: NowRegular;
    font-size: 15px;
    text-align: justify;
    line-height: 24px;
    color: black;
    opacity: 0.5;
}

.darkModeContactsBackground .darkModeDescription {
    font-family: NowRegular;
    font-size: 18.5px;
    text-align: justify;
    line-height: 24px;
    color: white;
    opacity: 0.5;
}

.contactPageBackground {
    width: 100%;
    position: absolute;
}

@media screen and (min-width: 550px) {

    .contactsBackground .largeTitle,
    .darkModeContactsBackground .darkModeLargeTitle {
        font-size: 45px;
    }
}

@media screen and (min-width: 600px) {

    .nameAndDescription .description,
    .darkModeNameAndDescription .darkModeDescription {
        font-size: 20px;
    }
}


@media screen and (min-width: 900px) {

    .contactsBackground .description,
    .darkModeContactsBackground .darkModeDescription {
        margin-left: 10%;
        margin-right: 10%;
    }
}

@media screen and (min-width: 1000px) {

    .nameAndDescription .description,
    .darkModeNameAndDescription .darkModeDescription {
        font-size: 21px;
    }
}

@media screen and (min-width: 1400px) {

    .nameAndDescription .description,
    .darkModeNameAndDescription .darkModeDescription {
        font-size: 22px;
    }
}


@media screen and (min-width: 1140px) {

    .contactsBackground .description,
    .darkModeContactsBackground .darkModeDescription {
        margin-left: unset;
        margin-right: unset;
    }
}

@media screen and (min-width: 1785) {

    .contactsBackground .description,
    .darkModeContactsBackground .darkModeDescription {
        margin-left: 0%;
        margin-right: 0%;
    }
}