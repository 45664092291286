@import '../../../assets/fonts/fonts.scss';
@import '../../../assets/colors/colors.scss';

.simpleDialogBackground {
    background-color: white;
}

.darkModeSimpleDialogBackground {
    background-color: $headerBackgroundColor;
    padding: 0px;
    margin: 0px;
}

.title {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 25px;
}

.darkModeTitle {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 25px;
    color: white;
}

.error {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 30px;
    color: red;
}

.description {
    font-family: SanFranciscoTextRegular;
    font-size: 17px;
}

.darkModeDescription {
    font-family: SanFranciscoTextRegular;
    font-size: 17px;
    color: white;
}