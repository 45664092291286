@import '../../../assets/colors/colors.scss';
@import '../../../assets/fonts/fonts.scss';

.appStore {
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 15px;
    background-color: $greyColor;
    border-radius: 25px;
    margin-left: 5%;
    margin-right: 5%;
}

.darkModeAppStore {
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 15px;
    margin-left: 5%;
    margin-right: 5%;
    background-color: $darkModeBackgroundColor;
    border-radius: 25px;
}

.appStoreTitle {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 5px;
}

.darkModeAppStoreTitle {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 5px;
    color: white;
}

.appStoreDescription {
    font-family: SanFranciscoTextRegular;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.darkModeAppStoreDescription {
    font-family: SanFranciscoTextRegular;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
}

.appStoreItem {
    text-align: center;
    font-size: 13px;
    color: $linkColor;
    margin-top: 10px;
    font-weight: 500;
}

.appStoreItem a {
    margin-top: 10px;
    color: unset;
    text-decoration: none;
}

.appStoreLink:hover {
    cursor: pointer;
}

.appStoreLinks {
    margin-left: -4%;
}

@media screen and (min-width: 350px) {
    .appStoreItem {
        font-size: unset;
    }
}

@media screen and (min-width: 400px) {

    .appStoreDescription,
    .darkModeAppStoreDescription {
        font-size: 18px;
    }

    .appStoreItem {
        font-size: 18px;
    }
}

@media screen and (min-width: 600px) {

    .appStore,
    .darkModeAppStore {
        margin-left: 0px;
        margin-right: 0px;
    }
}