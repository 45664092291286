@import '../../../assets/colors/colors.scss';
@import '../../../assets/fonts/fonts.scss';

.topComponent {
    background-color: $greyColor;
    padding-bottom: 10px;
}

.darkModeTopComponent {
    // background-color: $darkModeBackgroundColor;
    padding-bottom: 10px;
}

.topComponentImage {
    margin-left: 7.5%;
    padding-right: 7.5%;
    padding-top: 75px;
    width: 85%;
    height: 85%;
}

.projectNameAndDescription {
    margin-top: 20px;
    background-color: $greyColor;
}

.darkModeProjectNameAndDescription {
    margin-top: 20px;
    background-color: $blackColor;
}

.projectName,
.supportProjectName {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.darkModeProjectName,
.darkModeSupportProjectName {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
}

.projectDescription {
    background-color: $greyColor;
}

.darkModeDescriptionUnOrderedList {
    color: white;
}

@media screen and (min-width: 375px) {

    .projectName,
    .darkModeProjectName {
        font-size: 30px;
    }

    .projectDescription {
        font-size: 15px;
    }
}

@media screen and (min-width: 450px) {

    .projectName,
    .darkModeProjectName {
        font-size: 40px;
    }

    .projectDescription {
        font-size: 20px;
    }
}

@media screen and (min-width: 600px) {

    .projectName,
    .darkModeProjectName {
        white-space: nowrap;
        font-size: 42px;
    }

    .projectDescription {
        font-size: 25px;
    }

    .projectNameAndDescription {
        margin-top: 90px;
        background-color: $greyColor;
    }

    .darkModeProjectNameAndDescription {
        margin-top: 90px;
        background-color: $blackColor;
    }

    .descriptionUnOrderedList,
    .darkModeDescriptionUnOrderedList {
        margin-left: -30px;
    }
}

@media screen and (min-width: 700px) {

    .projectName,
    .darkModeProjectName {
        font-size: 50px;
    }

}

@media screen and (min-width: 900px) {

    .projectName,
    .darkModeProjectName {
        display: flex;
        align-items: center;
        justify-content: left;
    }

}

@media screen and (min-width: 1100px) {

    .descriptionUnOrderedList,
    .darkModeDescriptionUnOrderedList {
        margin-left: -35px;
    }

}

@media screen and (min-width: 1150px) {


    .supportProjectName,
    .darkModeSupportProjectName {
        white-space: nowrap;
        font-size: 42px;
    }
}

@media screen and (min-width: 1200px) {

    .topComponentImage {
        width: 350px;
        height: 350px;
    }
}

@media screen and (min-width: 1300px) {

    .descriptionUnOrderedList,
    .darkModeDescriptionUnOrderedList {
        margin-left: -25%;
    }

    .supportProjectName,
    .darkModeSupportProjectName {
        margin-left: -165px;
        font-size: 50px;
    }
}

@media screen and (min-width: 1400px) {

    .projectName,
    .darkModeProjectName {
        margin-left: -20px;
    }

    .descriptionUnOrderedList,
    .darkModeDescriptionUnOrderedList {
        margin-left: -35%;
    }

    .supportProjectName,
    .darkModeSupportProjectName {
        margin-left: -300px;
    }
}

@media screen and (min-width: 1500px) {

    .descriptionUnOrderedList,
    .darkModeDescriptionUnOrderedList {
        margin-left: -45%;
    }

    .projectName,
    .darkModeProjectName {
        margin-left: -15%;
    }

    .supportProjectName,
    .darkModeSupportProjectName {
        margin-left: -75%;
    }
}