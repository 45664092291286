.errorView {
    position: absolute;
    margin-left: 5%;
}

@media screen and (min-width: 350px) {
    .errorView {
        margin-left: 9%;
    }
}

@media screen and (min-width: 400px) {
    .errorView {
        margin-left: 11%;
    }
}

@media screen and (min-width: 425px) {
    .errorView {
        margin-left: 13%;
    }
}

@media screen and (min-width: 450px) {
    .errorView {
        margin-left: 5%;
    }
}

@media screen and (min-width: 475px) {
    .errorView {
        margin-left: 7%;
    }
}

@media screen and (min-width: 500px) {
    .errorView {
        margin-left: 9%;
    }
}

@media screen and (min-width: 525px) {
    .errorView {
        margin-left: 11%;
    }
}

@media screen and (min-width: 550px) {
    .errorView {
        margin-left: 12%;
    }
}

@media screen and (min-width: 575px) {
    .errorView {
        margin-left: 14%;
    }
}

@media screen and (min-width: 600px) {
    .errorView {
        margin-left: 7.5%;
    }
}

@media screen and (min-width: 625px) {
    .errorView {
        margin-left: 9.5%;
    }
}

@media screen and (min-width: 650px) {
    .errorView {
        margin-left: 11.5%;
    }
}

@media screen and (min-width: 675px) {
    .errorView {
        margin-left: 13.5%;
    }
}

@media screen and (min-width: 725px) {
    .errorView {
        margin-left: 14.5%;
    }
}

@media screen and (min-width: 750px) {
    .errorView {
        margin-left: 15.5%;
    }
}

@media screen and (min-width: 775px) {
    .errorView {
        margin-left: 16.5%;
    }
}

@media screen and (min-width: 800px) {
    .errorView {
        margin-left: 17.5%;
    }
}

@media screen and (min-width: 825px) {
    .errorView {
        margin-left: 18.5%;
    }
}

@media screen and (min-width: 850px) {
    .errorView {
        margin-left: 20%;
    }
}

@media screen and (min-width: 875px) {
    .errorView {
        margin-left: 21%;
    }
}

@media screen and (min-width: 925px) {
    .errorView {
        margin-left: 22%;
    }
}

@media screen and (min-width: 975px) {
    .errorView {
        margin-left: 23%;
    }
}

@media screen and (min-width: 1000px) {
    .errorView {
        margin-left: 24%;
    }
}

@media screen and (min-width: 1050px) {
    .errorView {
        margin-left: 25%;
    }
}

@media screen and (min-width: 1075px) {
    .errorView {
        margin-left: 26%;
    }
}

@media screen and (min-width: 1100px) {
    .errorView {
        margin-left: 27%;
    }
}

@media screen and (min-width: 1125px) {
    .errorView {
        margin-left: 28%;
    }
}

@media screen and (min-width: 1150px) {
    .errorView {
        margin-left: 29%;
    }
}

@media screen and (min-width: 1175px) {
    .errorView {
        margin-left: 30%;
    }
}

@media screen and (min-width: 1200px) {
    .errorView {
        margin-left: 31%;
    }
}

@media screen and (min-width: 1650px) {
    .errorView {
        margin-left: 33%;
    }
}

@media screen and (min-width: 2300px) {
    .errorView {
        margin-left: 35%;
    }
}