@import '../../../assets/fonts/fonts.scss';
@import '../../../assets/colors/colors.scss';

.projectSection {
    width: 90%;
    margin-left: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 18px;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.projectSection:hover {
    transform: scale(1.02);
    box-shadow: 0 0 15px 10px rgba(223, 223, 225, 0.0);
}

.projectItem {
    padding: 10px;
    height: auto;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.darkModeProjectItem {
    padding: 10px;
    height: auto;
    background-color: $darkModeBackgroundColor;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

a {
    color: unset;
    text-decoration: none;
}

.projectItemName {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.darkModeProjectItemName {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.projectItemDescription {
    font-family: SanFranciscoTextRegular;
    font-size: 18.5px;
    margin-top: 10px;
    text-align: center;
}

.darkModeProjectItemDescription {
    font-family: SanFranciscoTextRegular;
    font-size: 18.5px;
    margin-top: 10px;
    text-align: center;
    color: white;
}

.appStoreLink {
    text-align: center;
    color: $linkColor;
    margin-top: 10px;
    font-weight: 500;
}

.comingSoon {
    color: $comingSoonColor;
}

.appStoreLink a {
    margin-top: 10px;
    color: unset;
    text-decoration: none;
}

.projectImage {
    width: 100%;
    height: 100%;
    -webkit-user-drag: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    pointer-events: none;
    border-radius: 10px;
}

.rightArrow {
    width: 13px;
    height: 18.5px;
    margin-top: 2.5px;
    position: absolute;
    opacity: 0.95;
}

.projectClickToViewDetails {
    color: #6b6868;
    font-family: SanFranciscoTextRegular;
    font-size: 18.5px;
    margin-top: 10px;
    text-align: center;
}

.darkModeProjectClickToViewDetails {
    color: #6b6868;
    font-family: SanFranciscoTextRegular;
    font-size: 18.5px;
    margin-top: 10px;
    text-align: center;
}

@media screen and (min-width: 400px) {

    .projectItemDescription,
    .darkModeProjectItemDescription {
        font-size: 18.5px;
    }

    .projectClickToViewDetails,
    .darkModeProjectClickToViewDetails {
        font-size: 18.5px;
    }
}

@media screen and (min-width: 550px) {

    .projectItemDescription,
    .darkModeProjectItemDescription {
        font-size: 18.5px;
    }
}

@media screen and (min-width: 600px) {
    // .projectSection {
    // width: 100%;
    // margin-left: 5%;
    // }

    .projectItemDescription,
    .darkModeProjectItemDescription {
        font-size: 18.5px;
    }
}

@media screen and (min-width: 800px) {

    .projectItemName,
    .darkModeProjectItemName {
        font-size: 28px;
    }

    .projectItemDescription,
    .darkModeProjectItemDescription {
        font-size: 18.5px;
    }
}

@media screen and (min-width: 1100px) {

    .projectItemName,
    .darkModeProjectItemName {
        font-size: 30px;
    }

    .projectItemDescription,
    .darkModeProjectItemDescription {
        font-size: 19px;
    }
}


@media screen and (min-width: 1300px) {

    .projectItemName,
    .darkModeProjectItemName {
        font-size: 40px;
    }
}