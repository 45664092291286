#name {
  margin-top: 100px;
  font-family: monospace;
  font-size: 100px;
  text-align: center;
}

#underMaintenance {
  font-style: normal;
  text-align: center;
}

#socialMedia {
  font-size: 20px;
  font-style: normal;
  text-align: center;
}

#twitterSocialMediaIcon {
  margin-top: -10px;
  margin-bottom: -13px;
}

.col-sm-4 {
  color: black;
}

.heading {
  margin-top: -20px;
}
