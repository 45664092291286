@import '../../../assets/fonts/fonts.scss';
@import '../../../assets/colors/colors.scss';

.qualifications {
    width: 90%;
    margin-left: 5%;
}

.qualificationsSection {
    height: auto;
    width: 100%;
    background-color: $greyColor;
    margin-top: 40px;
    padding: 20px;
    border-radius: 25px;
}

.darkModeQualificationsSection {
    height: auto;
    width: 100%;
    margin-top: 40px;
    padding: 20px;
    background-color: $darkModeBackgroundColor;
    border-radius: 25px;
}

.qualicationsSubSection,
.darkModeQualicationsSubSection {
    margin-left: -20px;
}

.qualificationsTitle {
    font-family: NewYorkMedium;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.darkModeQualificationsTitle {
    font-family: NewYorkMedium;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    color: white;
}

.qualificationsItems {
    margin-bottom: 20px;
}

.qualificationsItemTitle {
    font-family: NewYorkMedium;
    padding-bottom: 5px;
    word-wrap: break-word;
}

.darkModeQualificationsItemTitle {
    font-family: NewYorkMedium;
    padding-bottom: 5px;
    word-wrap: break-word;
    color: white;
}

.qualificationsItemTitle.selected,
.darkModeQualificationsItemTitle.selected {
    font-family: NewYorkMedium;
}

.qualificationsItem {
    font-family: NewYorkMedium;
    font-size: 14px;
    padding-bottom: 5px;
    list-style: inside;
    text-indent: -1em;
}

.darkModeQualificationsItem {
    font-family: NewYorkMedium;
    font-size: 14px;
    padding-bottom: 5px;
    color: white;
    list-style: inside;
    text-indent: -1em;
}

.qualificationsItem.selected,
.darkModeQualificationsItem.selected {
    font-family: NewYorkMedium;
}

.qualificationsItem.a,
.darkModeQualificationsItem.a {
    color: $linkColor;
    font-family: NewYorkMedium;
    font-weight: 500;
    white-space: nowrap;
}

.downloadFile {
    display: flex;
    align-items: center;
    justify-content: center;
}

.qualificationsItemNoWrap {
    word-wrap: break-word;
}

@media screen and (min-width: 300px) {

    .qualificationsItem,
    .darkModeQualificationsItem {
        font-size: 15px;
    }
}


@media screen and (min-width: 400px) {

    .qualificationsTitle,
    .darkModeQualificationsTitle {
        font-size: 28px;
    }

    .qualificationsItem.a,
    .darkModeQualificationsItem.a {
        font-size: 16px;
    }
}

@media screen and (min-width: 445px) {

    .qualificationsTitle,
    .darkModeQualificationsTitle {
        white-space: nowrap;
    }
}

@media screen and (min-width: 600px) {
    .qualifications {
        width: 100%;
        margin-left: 0%;
    }

    .qualificationsTitle,
    .darkModeQualificationsTitle {
        font-size: 20px;
    }
}

@media screen and (min-width: 650px) {

    .qualificationsTitle,
    .darkModeQualificationsTitle {
        font-size: 20px;
        white-space: nowrap;
    }
}

@media screen and (min-width: 800px) {

    .qualificationsTitle,
    .darkModeQualificationsTitle {
        font-size: 25px;
    }

    .qualicationsSubSection,
    .darkModeQualicationsSubSection {
        padding-right: 30px;
        margin-left: unset;
    }
}


@media screen and (min-width: 883px) {
    .qualificationsItemNoWrap {
        white-space: nowrap;
    }
}

@media screen and (min-width: 900px) {

    .qualificationsTitle,
    .darkModeQualificationsTitle {
        font-size: 28px;
    }
}

@media screen and (min-width: 1000px) {

    .qualificationsTitle,
    .darkModeQualificationsTitle {
        font-size: 30px;
    }
}

@media screen and (min-width: 1500px) {
    // .qualificationList {
    //     padding-left: 130px;
    // }

    .downloadFile {
        margin-left: -20%;
    }
}