@import '../../assets/fonts/fonts.scss';
@import '../../assets/colors/colors.scss';

.headerBackground {
    background-color: $greyColor;
    position: fixed;
    height: 50px;
    top: 0;
    z-index: 10;
    max-width: 2200px;
}

.darkModeHeaderBackground {
    background-color: $blackColor;
    position: fixed;
    height: 50px;
    top: 0;
    z-index: 10;
    max-width: 2200px;
}

.headerNames {
    color: white;
}

.headerRightItems {
    display: none;
}

.headerName {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 18px;
    padding-left: 15px;
    margin-top: 15px;
    white-space: nowrap;
    left: 0px;
    position: absolute;
    color: black;
}

.darkModeHeaderName {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 18px;
    padding-left: 15px;
    margin-top: 15px;
    white-space: nowrap;
    left: 0px;
    position: absolute;
    color: white;
}

.tevonWallaceName {
    margin-left: 25px;
}

.tevonWallaceImage {
    margin-top: 1px;
    position: absolute;
    height: 20px;
    width: 20px;
    -webkit-user-drag: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    cursor: pointer;
}

.headerMenuIcon {
    width: 20px;
    height: 20px;
    position: absolute;
    margin-left: 10px;
    margin-top: -10px;
    right: 5px;
    filter: opacity(1) drop-shadow(0 0 0 black);
}

.darkModeHeaderMenuIcon {
    width: 20px;
    height: 20px;
    position: absolute;
    margin-left: 10px;
    margin-top: -10px;
    right: 5px;
    color: white;
}

.headerMenuIcon:hover,
.darkModeHeaderMenuIcon {
    cursor: pointer;
}

@keyframes fadeInOpacity {
    from {
        transform: translateY(-50px);
    }

    to {
        transform: translateY(0px);
    }
}

.headerName a,
.darkModeHeaderName a,
.headerItems a,
.darkModeHeaderItems a {
    text-decoration: none;
    font-family: SanFranciscoDisplaySemibold;
    color: unset;
}

.headerName:hover,
.darkModeHeaderName:hover {
    color: rgb(190, 186, 186);
}

.headerBackground .headerSideBar {
    position: fixed;
    margin-top: -20px;
    z-index: 10;
    height: 100%;
    width: 100%;
    background-color: $greyColor;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 2s;
    animation: fadeInOpacity 400ms ease-in-out 0s forwards;
}

.darkModeHeaderBackground .headerSideBar {
    position: fixed;
    margin-top: -25px;
    z-index: 10;
    height: 100%;
    width: 100%;
    background-color: $fadedBlackColor;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 2s;
    animation: fadeInOpacity 400ms ease-in-out 0s forwards;
}

.headerItems {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 18px;
    margin-top: -23px;
    padding-bottom: 10px;
    white-space: nowrap;
    color: black;
}

.darkModeHeaderItems {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 18px;
    margin-top: -23px;
    padding-bottom: 10px;
    white-space: nowrap;
    color: white;
}

.selectedHeaderItem {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 18px;
    margin-top: -23px;
    padding-bottom: 10px;
    white-space: nowrap;
    color: gray;
}

.headerItems:hover,
.darkModeHeaderItems:hover {
    cursor: pointer;
    color: rgb(190, 186, 186);
}

@media screen and (min-width: 600px) {
    .headerRightItems {
        display: block;
    }

    .headerMenuIcon,
    .darkModeHeaderMenuIcon {
        display: none;
    }

    .headerBackground .headerSideBar,
    .darkModeHeaderBackground .headerSideBar {
        display: none;
    }

    .headerName,
    .darkModeHeaderName {
        left: 0px;
        margin-left: 30%;
        padding-top: 3px;
        position: unset;
    }
}

@media screen and (min-width: 800px) {

    .headerName,
    .darkModeHeaderName {
        margin-left: 25px;
    }
}

@media screen and (min-width: 1100px) {

    .headerName,
    .darkModeHeaderName {
        margin-left: 35px;
    }
}

@media screen and (min-width: 1300px) {

    .headerName,
    .darkModeHeaderName {
        margin-left: 40px;
    }
}

@media screen and (min-width: 1500px) {

    .headerName,
    .darkModeHeaderName {
        margin-left: 50px;
    }
}