.circularProgress {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 2;
}

.MuiCircularProgress-colorPrimary {
    color: #65793C;
}
