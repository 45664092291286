@import '../../assets/fonts/fonts.scss';
@import '../../assets/colors/colors.scss';

.sideMenuNames {
    font-family: SanFranciscoDisplaySemibold;
    color: black;
    font-size: 30px;
    padding-left: 10px;
    padding-top: 20px;
    padding-bottom: 5px;
}

.darkModeSideMenuNames {
    font-family: SanFranciscoDisplaySemibold;
    color: white;
    font-size: 30px;
    padding-left: 10px;
    padding-top: 20px;
    padding-bottom: 5px;
}

.sideMenuProjects:hover,
.darkModeSideMenuProjects:hover {
    cursor: pointer;
}

.selectedSideMenu {
    color: black;
    opacity: 0.3;
}

.darkModeSelectedSideMenu {
    color: white;
    opacity: 0.3;
}

.sideMenuProjects a {
    text-decoration: none;
    font-family: SanFranciscoDisplaySemibold;
    color: black;
}

.darkModeSideMenuProjects a {
    text-decoration: none;
    font-family: SanFranciscoDisplaySemibold;
    color: white;
}

.divider {
    background-color: gray;
    height: 1px;
}

.closeMenuIcon {
    width: 19px;
    height: 19px;
    position: absolute;
    margin-top: 15px;
    right: 10px;
    opacity: 1;
    z-index: 10;
    animation-name: fadeInOpacityWithNoYTranslation;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 0s;
    animation: fadeInOpacityWithNoYTranslation 200ms ease-in-out 0s forwards;
}

.darkModeCloseMenuIcon {
    width: 19px;
    height: 19px;
    position: absolute;
    margin-top: 15px;
    right: 10px;
    opacity: 1;
    z-index: 10;
    animation-name: fadeInOpacityWithNoYTranslation;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 0s;
    animation: fadeInOpacityWithNoYTranslation 200ms ease-in-out 0s forwards;
    color: white;
}

@keyframes fadeInOpacityWithNoYTranslation {
    from {
        transform: translateY(0px);
    }

    to {
        transform: translateY(0px);
    }
}

.closeMenuIcon:hover,
.darkModeCloseMenuIcon:hover {
    cursor: pointer;
}

@media screen and (min-width: 635px) {

    .headerSideBar,
    .darkModeHeaderSideBar {
        display: none;
    }
}