@import '../../../assets/colors/colors.scss';
@import '../../../assets/fonts/fonts.scss';

.privacy {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 20px;
    padding-right: 20px;
    background-color: $greyColor;
    display: grid;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 25px;
}

.darkModePrivacy {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 20px;
    padding-right: 20px;
    background-color: $darkModeBackgroundColor;
    display: grid;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 25px;
}

.privacyTitle {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
    text-align: center;
    margin-top: 15px;
}

.darkModePrivacyTitle {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
    text-align: center;
    margin-top: 15px;
    color: white;
}

.privacyDescription {
    font-family: SanFranciscoTextRegular;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    text-align: center;
}

.darkModePrivacyDescription {
    font-family: SanFranciscoTextRegular;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    text-align: center;
    color: white;
}

.privacyItem {
    font-family: SanFranciscoTextRegular;
    font-size: 15px;
    padding-bottom: 10px;
    text-align: center;
    text-indent: -1em;
}

.darkModePrivacyItem {
    font-family: SanFranciscoTextRegular;
    font-size: 15px;
    padding-bottom: 10px;
    text-align: center;
}

.privacyList {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: 6%;
}

.listItem {
    list-style-position: inside;
    white-space: nowrap;
}

.darkModeListItem {
    color: white;
    list-style-position: inside;
    white-space: nowrap;
}

.darkModePrivacyItem {
    text-indent: -1em;
}

@media screen and (min-width: 400px) {

    .privacyDescription,
    .darkModePrivacyDescription {
        font-size: 18px;
    }

    .privacyItem,
    .darkModePrivacyItem {
        font-size: 18px;
    }
}

@media screen and (min-width: 600px) {

    .privacy,
    .darkModePrivacy {
        margin-left: 0px;
        margin-right: 0px;
    }

    .privacyItem,
    .darkModePrivacyItem {
        text-indent: 0.5em;
    }
}

@media screen and (min-width: 700px) {

    .privacyItem,
    .darkModePrivacyItem {
        text-indent: -1em;
    }
}

@media screen and (min-width: 1400px) {
    .privacyList {
        margin-left: 4%;
    }
}