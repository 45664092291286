@import '../../assets/fonts/fonts.scss';
@import '../../assets/colors/colors.scss';

.nameAndDescription {
    padding-top: 100px;
    min-height: 140px;
    height: auto;
    background-color: $greyColor;
}

.darkModeNameAndDescription {
    padding-top: 100px;
    min-height: 140px;
    height: auto;
    background-color: $blackColor;
}

.resumeName {
    font-family: NewYorkMedium;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.name {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.darkModeResumeName {
    font-family: NewYorkMedium;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.darkModeName {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.resumeDescription {
    font-family: NewYorkMedium;
    font-size: 16px;
    padding-left: 2%;
    padding-right: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    text-align: center;
}

.description {
    font-family: SanFranciscoTextRegular;
    font-size: 16px;
    padding-left: 2%;
    padding-right: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    text-align: center;
}

.darkModeResumeDescription {
    font-family: NewYorkMedium;
    font-size: 16px;
    padding-left: 2%;
    padding-right: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    text-align: center;
    color: white;
}


.darkModeDescription {
    font-family: SanFranciscoTextRegular;
    font-size: 16px;
    padding-left: 2%;
    padding-right: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    text-align: center;
    color: white;
}

.underMaintenance {
    font-family: SanFranciscoDisplaySemibold;
    font-size: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    color: rgb(156, 156, 4);
}

@media screen and (min-width: 375px) {

    .name,
    .resumeName,
    .darkModeName,
    .darkModeResumeName {
        font-size: 30px;
    }

    .description,
    .resumeDescription,
    .darkModeDescription,
    .darkModeResumeDescription {
        font-size: 18px;
    }

    .underMaintenance {
        font-size: 27px;
    }
}

@media screen and (min-width: 450px) {

    .name,
    .resumeName,
    .darkModeName,
    .darkModeResumeName {
        font-size: 40px;
    }

    .underMaintenance {
        font-size: 34px;
    }
}

@media screen and (min-width: 600px) {

    .name,
    .resumeName,
    .darkModeName,
    .darkModeResumeName {
        font-size: 50px;
    }
}

@media screen and (min-width: 800px) {

    .description,
    .resumeDescription,
    .darkModeDescription,
    .darkModeResumeDescription {
        font-size: 20px;
    }
}

@media screen and (min-width: 900px) {

    .description,
    .resumeDescription,
    .darkModeDescription,
    .darkModeResumeDescription {
        font-size: 23px;
    }

    .darkModeName,
    .name .resumeName .darkModeResumeName {
        font-size: 60px;
    }
}