// MARK: - Used for naming items such as names, headings etc
@font-face {
    font-family: SanFranciscoDisplaySemibold;
    src: url(../../assets/fonts/SF-Pro-Display-Semibold.otf)
}

// MARK: - Used for naming items such as header items
@font-face {
    font-family: SanFranciscoDisplayRegular;
    src: url(../../assets/fonts/SF-Pro-Display-Regular.otf)
}

// MARK: - Used for naming items such as texts in paragraphs
@font-face {
    font-family: SanFranciscoTextRegular;
    src: url(../../assets/fonts/SF-Pro-Text-Regular.otf)
}


// MARK: - Used for naming items such as texts in paragraphs
@font-face {
    font-family: NewYorkMedium;
    src: url(../../assets/fonts/NewYorkMedium-Medium.otf)
}