@import '../../assets/colors/colors.scss';

.entireProjectSection {
    background-color: $greyColor;
}

.darkModeEntireProjectSection {
    background-color: $blackColor;
}

.entireProjectSection .subProjectSection,
.darkModeEntireProjectSection .darkModeSubProjectSection {
    padding-bottom: 50px;
}

.nameAndDescription,
.darkModeNameAndDescription {
    font-size: 50px;
}

.nameAndDescription .name,
.darkModeNameAndDescription .darkModeName {
    font-size: 50px;
}

@media screen and (min-width: 800px) {

    .nameAndDescription .name,
    .darkModeNameAndDescription .darkModeName {
        font-size: 60px;
    }
}