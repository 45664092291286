@import '../../assets/colors/colors.scss';
@import '../../assets/fonts/fonts.scss';

.entireVisualFileEditorSection .additionalDescriptions {
    font-family: SanFranciscoTextRegular;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-bottom: 10px;
    padding-right: 10px;
    color: black;
}

.darkModeEntireVisualFileEditorSection .darkModeAdditionalDescriptions {
    font-family: SanFranciscoTextRegular;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-bottom: 10px;
    padding-right: 10px;
    color: white;
}

@media screen and (min-width: 400px) {

    .entireVisualFileEditorSection .additionalDescriptions,
    .darkModeEntireVisualFileEditorSection .darkModeAdditionalDescriptions {
        font-size: 18px;
    }
}

@media screen and (min-width: 800px) {

    .entireVisualFileEditorSection .additionalDescriptions,
    .darkModeEntireVisualFileEditorSection .darkModeAdditionalDescriptions {
        font-size: 17px;
    }
}

@media screen and (min-width: 1000px) {

    .entireVisualFileEditorSection .additionalDescriptions,
    .darkModeEntireVisualFileEditorSection .darkModeAdditionalDescriptions {
        font-size: 18px;
    }
}