@import '../../assets/fonts/fonts.scss';
@import '../../assets/colors/colors.scss';

.footer {
    bottom: 0;
    position: fixed;
    background-color: $greyColor;
    height: 40px;
    max-width: 2200px;
}

.darkModeFooter {
    bottom: 0;
    position: fixed;
    background-color: $blackColor;
    height: 40px;
    max-width: 2200px;
}

.footerItems {
    font-family: SanFranciscoTextRegular;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 13px;
    text-decoration: none;
}

.darkModeFooterItems {
    font-family: SanFranciscoTextRegular;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 13px;
    text-decoration: none;
    color: white;
}

.footerItems a {
    text-decoration: none;
    font-family: SanFranciscoTextRegular;
    color: unset
}

.footerItems:hover {
    cursor: pointer;
    text-decoration: underline;
}