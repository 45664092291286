$greyColor: #FAFAFA;
$linkColor: #306EC3;
$comingSoonColor: #DD6129;
$headerBackgroundColor: #323232;
$buttonBackgroundColor: #FAFAFA;
$blackColor: #000000;
$darkModeBackgroundColor: rgb(32, 31, 31);
$fadedBlackColor: rgb(22, 22, 23);

:export {
    headerBackgroundColor: $headerBackgroundColor;
    greyColor: $greyColor;
    blackColor: $blackColor;
    darkModeBackgroundColor: $darkModeBackgroundColor;
    fadedBlackColor: $fadedBlackColor;
}